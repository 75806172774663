<template>
  <main class="main reg">
    <section class="section">
      <div class="container">
        <div class="reg__content">
          <LoadingIndicator class="form-loading" v-if="loading"/>
          <h4 class="confirm-email__title" v-else>{{ message }}</h4>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import LoadingIndicator from "@/components/LoadingIndicator.vue";

export default {
  name: "ConfirmEmail",
  components: { LoadingIndicator },
  data() {
    return {
      loading: false,
      message: "Нет данных для подтверждения почты",
      error: "Ошибка.",
    }
  },
  created() {
    const urlParams = new URLSearchParams(window.location.search);
    let confirmCode = urlParams.get("code")
    let personID = urlParams.get("id")
    if (confirmCode && personID) {
      this.loading = true
      this.$store.dispatch("LINK_CONFIRM", { confirmCode, personID }).then(({ data }) => {
        this.loading = false
        if (data && data.result.state === "Success") {
          this.message = "Почта подтверждена, спасибо"
          urlParams.delete("code");
          urlParams.delete("id");
          history.replaceState(null, "", location.pathname + urlParams);
          this.redirect();
        } else if (data.result.state === "Error") {
          this.message = data.result.message || this.error
        }
      })
      .catch(() => {
        this.message = this.error
      })
    }
  },
  methods: {
    redirect() {
      if (this.$store.state.config.url_redirect) {
        setTimeout(() => {
          window.location = this.$store.state.config.url_redirect
        }, this.$store.state.config.redirect_timer)
      }
    }
  }
}
</script>

<style lang="stylus">
.reg {
  justify-content center

  &__content {
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    padding: 42px 24px;
    background: var(--color_white);
    border-radius: 10px;
    box-shadow: 0 20px 60px rgba(0, 0, 0, 0.15);
    +below(420px) {
      padding 20px
    }
  }
}

.confirm-email__title {
  text-align center
  margin 0
}
</style>